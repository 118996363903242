import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import { Container, Section, decorateHtml } from '../components/ui';

interface SimplePageProps {
  data: {
    simplePage: {
      id: string;
      title: string;
      slug: string;
      description: string;
      image: { id: string; url: string };
      html: string;
    };
  };
}

export default function SimplePage(props: SimplePageProps): JSX.Element {
  const { simplePage } = props.data;

  return (
    <Layout {...simplePage}>
      <Section className="bg-white">
        <Container py>
          <h1>{simplePage.title}</h1>
          <div
            dangerouslySetInnerHTML={{
              __html: decorateHtml(simplePage.html),
            }}
          />
        </Container>
      </Section>
    </Layout>
  );
}

export const query = graphql`
  query SimplePageContent($id: String!) {
    simplePage(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        url
      }
      html
    }
  }
`;
